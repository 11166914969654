<template>
  <SuccessBanner
    :key="key"
    :msg="successMsg"
    :active="activeBanner"
    @closeBanner="activeBanner = false"
    :isSuccess="isSuccess"
  />
  <div class="p-10">
    <FormTemplate
      :submit="false"
      formTitle="New Prospect"
      :form="form1"
      :showFormDefault="true"
      v-model:registrationNumber="formData.registrationNumber"
      v-model:representative_id="formData.representative_id"
      v-model:name="formData.name"
      v-model:comment="formData.comment"
    />
    <FormTemplate
      :submit="false"
      formTitle="Identity"
      :form="form2"
      v-model:customerAccount="formData.customerAccount"
      v-model:origin_id="formData.origin_id"
      v-model:activityCode="formData.activityCode"
      v-model:type="formData.type"
      v-model:capital="formData.capital"
    />
    <FormTemplate
      :submit="false"
      formTitle="Contact Information"
      :form="form3"
      v-model:mainAddressLine1="formData.mainAddressLine1"
      v-model:mainAddressLine2="formData.mainAddressLine2"
      v-model:mainAddressPostalCode="formData.mainAddressPostalCode"
      v-model:mainAddressCity="formData.mainAddressCity"
      v-model:mainAddressCountry="formData.mainAddressCountry"
      v-model:website="formData.website"
    />
    <FormTemplate
      @onSubmit="onSubmit('Customer created successfully.')"
      @dataFromTemplate="consoleData($event)"
      :submit="true"
      formTitle="Commercial Information"
      :form="form4"
      v-model:language="formData.language_id"
      v-model:chargeVAT="formData.chargeVAT"
      v-model:brand="formData.brand_id"
      v-model:mergeInvoices="formData.mergeInvoices"
      :formData="formData"
    />
  </div>
</template>

<script>
import axios from "axios";

import FormTemplate from "../../components/FormTemplate.vue";
import SuccessBanner from "../../components/SuccessBanner.vue";

export default {
  components: {
    FormTemplate,
    SuccessBanner,
  },
  data() {
    return {
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: false,
      formData: {
        brand_id: 0,
        representative_id: 0,
        customerAccount: "string",
        accountsReference: null,
        name: "string",
        brand: null,
        registrationNumber: null,
        taxRegistrationNumber: null,
        type: null,
        capital: null,
        activityCode: null,
        sector_id: null,
        origin_id: null,
        mainAddressLine1: "string",
        mainAddressLine2: "string",
        mainAddressPostalCode: "string",
        mainAddressCity: "string",
        mainAddressCountry: "string",
        website: null,
        active: 0,
        isReseller: 0,
        resellerLevel: 0,
        language_id: 0,
        chargeVAT: 0,
        applyVATAutoReverseOperators: 0,
        language: "string",
        comment: null,
        mergeInvoices: 0,
        onCallDuty: 0,
        vip: 0,
      },
      form1: [
        {
          name: "Registration Number",
          label: "registrationNumber",
          type: "input",
          required: true,
        },

        {
          name: "Representative ID",
          label: "representative_id",
          type: "input",
          required: false,
        },
        {
          name: "Comment",
          label: "comment",
          type: "input",
          required: false,
        },
        {
          name: "Name",
          label: "name",
          type: "input",
          required: true,
        },
      ],
      form2: [
        {
          name: "Customer account",
          label: "customerAccount",
          type: "input",
          required: false,
        },

        {
          name: "Origin",
          label: "origin_id",
          type: "select",
          options: [
            { name: "Choose", id: 0 },
            { name: "Personal prospecting", id: 1 },
            { name: "Lead via Google", id: 2 },
            { name: "Lead - another", id: 3 },
            { name: "Recommendation", id: 4 },
            { name: "business provider to remunerate", id: 5 },
            { name: "Another", id: 6 },
          ],
          required: false,
        },
        {
          name: "Activity",
          label: "activityCode",
          type: "select",
          options: [
            { name: "Choose", id: 0 },
            { name: "Administration", id: 1 },
            { name: "Education", id: 2 },
            { name: "Finance", id: 3 },
            { name: "Industry", id: 4 },
            { name: "Services", id: 5 },
            { name: "Transport", id: 6 },
          ],
          required: false,
        },
        {
          name: "Type",
          label: "type",
          type: "input",
          required: false,
        },
        {
          name: "Capital",
          label: "capital",
          type: "input",
          required: false,
        },
      ],
      form3: [
        {
          name: "Address line 1",
          label: "mainAddressLine1",
          type: "input",
          required: true,
        },

        {
          name: "Address line 2",
          label: "mainAddressLine2",
          type: "input",
          required: false,
        },
        {
          name: "Postal Code",
          label: "mainAddressPostalCode",
          type: "input",
          required: false,
        },
        {
          name: "City",
          label: "mainAddressCity",
          type: "input",
          required: false,
        },
        {
          name: "Country",
          label: "mainAddressCountry",
          type: "input",
          required: false,
        },
        {
          name: "Website",
          label: "website",
          type: "input",
          required: false,
        },
      ],
      form4: [
        {
          name: "Language",
          label: "language_id",
          type: "select",
          options: [
            { name: "Choose", id: 0 },
            { name: "English", id: 1 },
            { name: "French", id: 2 },
          ],
          required: true,
        },
        {
          name: "Brand",
          label: "brand",
          type: "select",
          options: [
            { name: "Choose", id: 0 },
            { name: "Encom", id: 1 },
          ],
          required: false,
        },
        {
          name: "Merge invoices",
          label: "mergeInvoices",
          type: "checkbox",
          value: 1,
          required: false,
        },
        {
          name: "Charge VAT",
          label: "chargeVAT",
          type: "checkbox",
          value: 1,
          required: false,
        },
      ],
    };
  },
  methods: {
    consoleData(event) {
      console.log(event);
    },
    onSubmit(msg) {
      let data = this.formData;
      console.log(data);
      axios
        .post(`${this.$cookie.getCookie("API")}/api/v1/customers`, data)
        .then((res) => {
          console.log(res);
          this.activeBanner = true;
          this.key = !this.key;
          this.successMsg = msg;
          this.isSuccess = "Sorry, something went wrong.";
        })
        .catch((error) => {
          this.errorHandling(error);
        });
    },
  },
};
</script>

<style></style>
